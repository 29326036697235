@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Caudex:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caudex:ital,wght@0,400;0,700;1,400;1,700&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

*,
html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Mulish", sans-serif;
}

*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.dark *::-webkit-scrollbar-track {
    background-color: #9b9b9b !important;
}

.dark *::-webkit-scrollbar-thumb {
    background-color: #646464;
}

*::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c1c1c1;
}

button:focus,
a:focus,
input:focus,
textarea:focus,
button:focus-within,
a:focus-within,
input:focus-within,
textarea:focus-within,
button:focus-visible,
a:focus-visible,
input:focus-visible,
textarea:focus-visible,
div:focus,
div:focus-visible,
li:focus,
li:focus-visible {
    outline: none;
}

.submenu::-webkit-scrollbar {
    display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.service-status-active:hover {
    background: linear-gradient(90deg, #F6E0C7 0%, rgba(255, 255, 255, 0.00) 138.82%);
}

.service-status-normal {
    background: linear-gradient(90deg, rgba(176, 176, 176, 0.20) 0%, rgba(255, 255, 255, 0.00) 182.67%);
}

.home-gif {
    background: url("../images/ca24e4c56e3da5dfb966b8ce908bc323.gif"), lightgray 0px 0px / 100% 115.246% no-repeat;
    background-size: cover;
    background-position-y: center;
}

.station-radio-btn .p-radiobutton-box.p-highlight {
    border-color: #61095A !important;
    background: #61095A !important;
}

.station-radio-btn .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #61095A !important;
}

.station-radio-btn .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none !important;
}

.station-radio-btn .p-radiobutton-box .p-radiobutton-icon {
    width: 10px !important;
    height: 10px !important;
}

.station-radio-btn,
.station-radio-btn .p-radiobutton-box {
    width: 18px !important;
    height: 18px !important;
}

.style-module_emptyIcons__Bg-FZ {
    display: flex !important;
}

.language svg {
    width: 15px;
    height: 15px;
}

.brand-icon {
    filter: brightness(0) invert(1);
}

.dark .no-lost-item {
    filter: brightness(0) invert(1);
}

.event:hover .pattern16 {
    filter: brightness(0) invert(1);
}

.city-line:hover img {
    filter: none !important;
    opacity: 1 !important;
}

.city-line-plan:hover img {
    filter: invert(1) brightness(2) !important;
    opacity: 1 !important;
}

.city-line-img {
    filter: grayscale(1);
    opacity: 0.3;
}

.city-line-img-plan {
    filter: invert(1) brightness(2);
    opacity: 1;
}

.hover-bg1 {
    background-image: url('../images/opportunities/business.png');
    background-size: cover;
    background-repeat: no-repeat;
}

/* CSS for the image */
.hover-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    border-radius: 9999px;
}

.opportunities:hover path {
    fill: #fff;
}


.star div:focus {
    outline: none;
}

.card {
    transition: all 1s ease;
}

.submenu {
    transition: all ease 0.3s;
    transition-delay: 0.3s;
}

.search {
    transition: width ease 0.3s;
}

.select-places {
    transition: width ease 0.3s;
}

.menu:hover .submenu {
    max-height: 800px;
}

.css-1413zdf-indicatorContainer svg,
.css-19p4ft6-indicatorContainer svg {
    width: 15px !important;
    height: 15px !important;
}

.react-datepicker__triangle {
    display: none;
}

.fadeInUp {
    animation: fadeInUp 0.5s ease backwards;
}

.fadeInDown {
    animation: fadeInDown 0.5s ease backwards;
}

.fadeInLeft {
    animation: fadeInLeft 0.4s ease backwards;
}

.fadeInRight {
    animation: fadeInRight 0.4s ease backwards;
}

.city:hover .city-arrow {
    animation: do 1.5s none ease-in-out infinite;
}

.card-arrow {
    animation: do 1.5s none ease-in-out infinite;
}

.update:hover svg {
    animation: do 1.5s none ease-in-out infinite;
}

.possibilities:hover .possibilities-arrow {
    animation: do 1.5s none ease-in-out infinite;
}

.caret {
    transition: 0.5s;
}

.menu:hover .caret {
    transform: rotate(180deg);
}

.caret:is(.collapsed) {
    transform: rotate(0deg);
}

.form-caret {
    transition: 0.5s;
    transform: rotate(0deg);
}

.active-form-caret {
    transform: rotate(180deg);
}

.result-caret {
    transition: 0.5s;
    transform: rotate(0deg);
}

.active-result-caret {
    transform: rotate(180deg);
}

.toFlags {
    visibility: hidden;
}

.fromFlags {
    visibility: hidden;
}

.places {
    visibility: hidden;
}

.airport {
    animation: airport 1s;
}

.information.container img {
    border-radius: 20px;
    width: 400px;
}

.p-paginator {
    background-color: transparent !important;
}

.dark .p-paginator-prev,
.dark .p-paginator-first,
.dark .p-paginator-last,
.dark .p-paginator-next,
.dark .p-paginator-page {
    color: white !important;
}

.dark .p-paginator-prev:hover,
.dark .p-paginator-first:hover,
.dark .p-paginator-last:hover,
.dark .p-paginator-next:hover,
.dark .p-paginator-page:hover {
    color: #6c757d !important;
}

.dark .p-highlight {
    color: #4338CA !important;
}

.journey-bullets .swiper-pagination {
    position: relative !important;
    width: max-content !important;
    height: 16px !important;
    bottom: -25px !important;
}

.journey-bullets .swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
    border-radius: 2px !important;
    width: 28px;
    height: 4px;
}

.journey-bullets .swiper-pagination .swiper-pagination-bullet-active {
    background: #61095A;
    width: 52px;
    height: 4px;
}

.dark .journey-bullets .swiper-pagination .swiper-pagination-bullet {
    background: #ddd;
}

.dark .journey-bullets .swiper-pagination .swiper-pagination-bullet-active {
    background: white;
}

.journey-imgs .swiper-slide,
.journey-imgs .swiper-slide-shadow-left,
.journey-imgs .swiper-slide-shadow-right {
    opacity: 0.4 !important;
    border-radius: 20px;
    background-image: none !important;
}

.journey-imgs .swiper-slide-active .swiper-slide-shadow-left,
.journey-imgs .swiper-slide-active .swiper-slide-shadow-right {
    background-image: none !important;
}

.journey-imgs .swiper-slide-active {
    opacity: 1 !important;
}


.feedback .bg-white {
    background: #FFFFFF;
    box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
}

.feedback .information .info {
    font-family: 'Caudex';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #FFFFFF;
}

.feedback .information .sub-info {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #C9C9C9;
}

.feedback .information .city {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.feedback .information .phone {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.feedback .information .connect {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}

.feedback-form h1 {
    font-family: 'Caudex';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #61095A;
}

.feedback-form h2 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #2D2D2D;
    opacity: 0.5;
}

.feedback-form button {
    background: #61095A;
    box-shadow: 0px 0.785237px 3.14095px rgba(0, 0, 0, 0.25);
    border-radius: 9.42284px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    height: 40px;
}

.go-smart-card-section {
    background: linear-gradient(147deg, rgba(73, 201, 186, 0.35) 14.22%, rgba(238, 238, 238, 0) 58.6%, rgba(73, 201, 186, 0) 58.6%), linear-gradient(153deg, rgb(252, 252, 252) 4.86%, rgb(248, 248, 248) 155.03%);
}

.card-img .slider.absolute {
    position: absolute !important;
}

.description-3-lines {
    max-height: 3 * (1em);
    /* 3 lines at 1em font-size */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Number of lines to show */
    -webkit-box-orient: vertical;
}

.child:nth-child(odd) .lost-item {
    background: #F4F7FD;
}

#home p {
    text-align: justify;
}

#home input:hover {
    border-color: #999;
    /* Change border color on hover */
}

#home input:focus {
    border-color: #333;
    /* Change border color on focus */
    outline: none;
    /* Remove default focus outline */
}

/* facility css */
/* .facility:hover {
    opacity: 0.2;
} */

.facility:hover img {
    filter: none;
}

.facility:hover p {
    color: #61095A;
}

.facility:focus img {
    filter: none;
}

.facility:focus p {
    color: #61095A;
}

.facility img {
    filter: invert(40%) sepia(100%) saturate(0%) hue-rotate(180deg) brightness(90%) contrast(100%);
}

.facility.active-facility-item img {
    filter: none;
}

.facility.active-facility-item p {
    color: #61095A;
}

/* facility css */

/* feature css */
.feature {
    border-radius: 20px;
    background: linear-gradient(90deg, rgba(255, 220, 252, 0.30) 0%, #FFF 87%);
}

.dark .feature {
    background: linear-gradient(90deg, rgb(131 131 131 / 30%) 0%, #FFF 87%) !important;
}

.feature-content {
    background: rgba(240, 240, 240, 0.50);
}

.dark .feature-content {
    background: #37373780 !important;
}

.feature .active {
    background: linear-gradient(90deg, rgba(169, 20, 157, 0.10) 0%, rgba(255, 255, 255, 0.00) 100%);
}

.feature .active h1 {
    color: #61095A;
}

.feature .feature-border {
    border-top: 1px solid #EBEBEB;
    border-bottom: 1px solid #EBEBEB;
}

.feature .feature-border svg {
    flex-shrink: 0;
}

.feature .station-facilities svg {
    flex-shrink: 0;
    min-height: 91px;
}

/* feature css */

/* faqs css */
.mini-faqs .faqs {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

/* faqs css */

.stacked-carousel-slide {
    transition: transform 0.3s ease;
    border-radius: 20px;
    opacity: 0.6;
    transform: scale(0.9);
}

.stacked-carousel-slide.center {
    opacity: 1;
    transform: scale(1);
}

.stacked-carousel-slide.visible {
    opacity: 0.8;
    transform: scale(0.95);
}

.stacked-carousel-slide img {
    border-radius: 20px;
}

.card-arrow {
    transform: rotate(45deg);
}

.hidden-content {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
}

.visible-content {
    max-height: 1000px;
    /* Adjust as necessary */
    opacity: 1;
    transition: max-height 0.5s ease, opacity 0.5s ease;
}

.line-bg {
    background: linear-gradient(90deg, #F2F2F2 -2.09%, rgba(217, 217, 217, 0.00) 50%);
}

/*dark theme css*/

.dark #home {
    background: #272727 !important;
}

.dark #home .main-title {
    color: #fff !important;
}

.dark #home thead {
    background: #ffff00 !important;
}

.dark #home th {
    color: #000 !important;
}

.dark #home h2 {
    color: #fff;
}

.dark #home a {
    color: #ffff00;
}

.dark #home .contact {
    background: #333333 !important;
}

.dark #home .dark\:bg-grey {
    background: #333333 !important;
}

.dark #home .dark\:text-white {
    color: #fff !important;
}

.dark #home .dark\:text-grey {
    color: #272727 !important;
}

.dark #home .title.dark\:text-grey {
    color: #272727 !important;
}

.dark #home .dark\:text-gray p.desc {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.dark #home .desc.dark\:text-gray {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity)) !important;
}

.dark #home h1 {
    color: #ffff00;
}

.dark #home .press-release h1 {
    color: #fff !important;
}

.dark #home .press-release-section {
    background: #333333 !important;
}

.dark #home .press-release-card .content div button {
    color: #fff !important;
}

.dark #home .latest-update {
    background: #333333 !important;
}

.dark .line-bg {
    background: linear-gradient(90deg, #d9d9d91c -2.09%, #333333 50%);
}

.dark #home .dark-bg {
    background: linear-gradient(180deg, #5d5c5c 0%, #272727 100%) !important;
}

.dark #home .text-secondaryText {
    color: #fff;
}

.dark .bg-primary {
    background: #ffff00;
    color: #000 !important;
}

.dark .bg-primary:hover {
    background: #ffff00 !important;
    color: #000 !important;
}

.dark .bg-primary:after {
    background: #ffff00 !important;
}

.dark .text-primary {
    color: #fff;
}

.dark .text-secondary {
    color: #fff;
}

.dark header .submenu li:hover {
    background: #ffff00 !important;
    color: #000 !important;
}

.dark header .text-secondary {
    color: #ffff00 !important;
}

.dark header .text-primary {
    color: #ffff00 !important;
}

.dark header .text-primary:after {
    background: #ffff00 !important;
}

.dark header li {
    color: #ffff00 !important;
}

.dark header li:after {
    background: #ffff00 !important;
}

.dark #home .title,
.dark #home .subtitle,
.dark #home .desc,
.dark #home .desc strong {
    color: #fff;
}

.dark #home .container .lbl-toggle {
    color: #ffff00 !important;
    border-color: #ffff00 !important;
    background: #333333 !important;
}

.dark #home tbody>tr {
    color: #ffff00 !important;
    background: #333333 !important;
}

.dark #home tbody>tr>td {
    color: #fff !important;
    background: #333 !important;
}

.dark #home p {
    color: #fff;
}

.dark #home .faqs.container p {
    color: #333333 !important;
}

.dark #home .cvo-profile-section .profile {
    background: #333333 !important;
}

.dark #home .text-secondaryText.dark\:text-grey {
    color: #333333 !important;
}

.dark .journey.container .title,
.dark .journey.container .subtitle,
.dark .journey.container .desc {
    color: #fff !important;
}

.dark .about-container .title {
    color: #fff;
}

.dark .about {
    background: #000 !important;
}

.dark .about .about-container .journey-mission .person-info-section .card-section:hover {
    background: linear-gradient(192deg, #61095A 19.51%, #8A0680 82.3%) !important;
}

.dark .about .about-container .title,
.dark .about .about-container .desc {
    color: #fff;
}

.dark .vission .title,
.dark .vission .desc {
    color: #fff;
}

.dark .transformation .title,
.dark .transformation .desc {
    color: #fff !important;
}

.dark .certificate .title,
.dark .certificate .desc {
    color: #fff !important;
}

.dark .text-primary.dark\:text-grey {
    color: #272727 !important;
}

.dark\:bg-grey label {
    color: #fff !important;
}

.banner-animation {
    animation: banner 4s ease-in-out;
}

.press-release-detail .content p {
    font-weight: 400;
    --tw-text-opacity: 1;
    color: rgb(113 128 150);
    font-size: 1rem;
}

/* Increase width of vertical scrollbar */
::-webkit-scrollbar {
    width: 10px;
    /* Adjust as needed */
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the scrollbar handle */
    border-radius: 10px;
    /* Rounded corners */
}

/* Increase height of horizontal scrollbar */
::-webkit-scrollbar {
    height: 10px;
    /* Adjust as needed */
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the scrollbar handle */
    border-radius: 10px;
    /* Rounded corners */
}

.marquee-container {
    display: flex;
    /* Display images in a row */
    overflow: hidden;
    /* width: fit-content; */
    width: 100%;
}

.card {
    flex: 0 0 auto;
    /* Ensure each image container takes up only the necessary space */
    margin-right: 20px;
    /* Add spacing between images */
}

.card:last-child {
    margin-right: 0;
    /* Remove margin for the last image */
}

.marquee-container>div {
    display: inline-block;
    white-space: nowrap;
    animation: marquee 20s linear infinite;
}

.marquee {
    display: flex;
    animation: marquee-scroll 10s linear infinite;
    gap: 20px;
    /* Adjust as needed for spacing between items */
    overflow: hidden;
    /* Hide overflow content */
}

.marquee.paused {
    animation-play-state: paused;
}

@keyframes marquee-scroll {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes banner {
    0% {
        transform: scale(1.0);
    }

    100% {
        transform: scale(1.3);
    }
}

/*dark theme css*/

@keyframes airport {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes do {
    0% {
        opacity: 0;
        transform: translateX(-10px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(-80px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(50px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInUp {
    0% {
        transform: translate(0px, 80px);
        opacity: 0;
    }

    100% {
        transform: translate(0px, 0);
        opacity: 1;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(0);
    }

    to {
        opacity: 1;
        transform: translateY(80px);
    }
}

@keyframes scrollAnimation {
    0% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(-100%);
    }
}

/* container */

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    .container {
        max-width: 1320px;
    }
}

@media (min-width: 1600px) {
    .container {
        max-width: 1410px;
    }
}

@media (min-width: 1800px) {
    .container {
        max-width: 1650px;
    }
}

/* home banner */

@media only screen and (max-width: 1100px) {
    .banner-coverflow {
        margin-left: -340px !important;
    }
}

@media only screen and (max-width: 768px) {
    .banner-coverflow {
        margin-left: -365px !important;
    }
}

@media only screen and (max-width: 639px) {
    .banner-coverflow {
        margin-left: -335px !important;
    }

    .select-input * {
        font-size: 0.875rem !important;
    }

    .select-input .css-1fsjinx-control,
    .select-input .css-18y544e-control {
        min-height: 40px !important;
    }
}

@media only screen and (max-width: 450px) {
    .menu-container {
        max-width: none !important;
        width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .header-container {
        max-width: none !important;
        width: 100% !important;
        padding: 10px !important;
        margin: 0 !important;
    }
}

/* otp */
.group-input input {
    border: 1px solid #DBDBDB !important;
    border-radius: 10px !important;
    width: 51px !important;
    height: 51px !important;
    color: #2d2d2d !important;
}

.group-input input {
    @apply !text-base sm:!text-lg
}

.group-input input:focus,
.group-input input:hover {
    border: 1px solid #999 !important;
}

.group-input div {
   gap: 15px !important;
}

@media (max-width: 450px) {
    .group-input input {
        width: 35px !important;
        height: 35px !important;
    }

    .group-input div {
        gap: 10px !important;
    }
}