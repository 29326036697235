/* main home page detai; */

.home-banner .section {
    @apply flex flex-col
}

.home-banner .subtitle {
    @apply text-[#F4F4F4] text-xs 1xl:text-sm font-bold pb-[8px]
} 

.home-banner .title {
    @apply text-2xl sm:text-3xl 1xl:text-[2.5rem] sm:leading-[43.2px] font-bold font-caudex text-white
}

.home-banner .desc {
    @apply text-[#F4F4F4] text-xs 1xl:text-sm font-normal pt-[15px]
}

.possibilities .desc {
    @apply text-[#29524A] text-sm font-normal max-w-[370px]
}

.director .title {
    @apply text-primary font-bold text-sm sm:text-base pt-[20px]
}

.director .desc {
    @apply text-[#969696] text-xs sm:text-sm font-normal max-w-[688px]
}

.director .profession {
    @apply text-xs font-bold text-[#969696] lg:pt-[4px]
}

/* city page detail */

.city-banner .section {
    @apply flex flex-col
}

.city-banner .title {
    @apply text-white text-2xl sm:text-3xl 1xl:text-[2.5rem] sm:leading-[43.2px] font-bold font-caudex pt-[10px] uppercase max-w-[741px]
}

.city-banner .subtitle {
    @apply text-white text-sm 1xl:text-base font-bold
}

.event .desc {
    @apply text-xs sm:text-sm font-normal text-darkGray dark:!text-darkGray pt-[8px]
}

.brand .section {
    @apply flex flex-col gap-[10px] md:gap-[20px] max-w-[399px]
}

.brand .subtitle {
    @apply text-[#29524ab3] text-xs 1xl:text-sm 2xl:text-base font-semibold
}

.brand .title {
    @apply font-caudex text-2xl sm:text-3xl font-bold
}

.brand .desc {
    @apply text-[#29524A] text-xs 1xl:text-sm 2xl:text-base font-normal
}

.venue .section {
    @apply flex flex-col gap-[10px] md:gap-[20px] max-w-[509px] z-[2] relative
}

.venue .subtitle {
    @apply text-[#29524ab3] text-xs 1xl:text-sm 2xl:text-base font-semibold
}

.venue .title {
    @apply font-caudex text-2xl sm:text-3xl font-bold
}

.venue .desc {
    @apply text-[#29524A] text-xs 1xl:text-sm 2xl:text-base font-normal
}

.event-enjoy .desc {
    @apply text-xs 1xl:text-sm font-normal
}

.latest-update .main-section {
    @apply flex items-center pr-[40px] gap-[40px]
}

.latest-update .section {
    @apply flex items-center gap-[8px] cursor-pointer
}

.latest-update .sub-section {
    @apply flex gap-[15px]
}

.latest-update .title {
    @apply text-primary font-bold text-xs
}

.latest-update .desc {
    @apply w-[247px] text-[#5C5C5C] text-sm font-normal
}

/* content */
.project-overview {
    @apply w-full h-full py-[30px] md:py-[50px] xxl:py-[60px]
}

.project-overview .branch {
    @apply flex flex-col gap-[8px] items-center justify-center w-full
}

.project-overview .branch .branch-svg {
    @apply rotate-[128deg] fill-secondary
}

.project-overview .branch .branch-item {
    @apply bg-[rgba(255,220,252,0.3)] w-fit px-[8px] h-[30px] flex items-center justify-center text-primary font-bold text-sm
}

.project-overview .card-section {
    @apply grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 gap-[20px]
}

.project-overview .card-section .card {
    @apply w-full h-full bg-white shadow-md
}

.project-overview .card-section .card .card-content {
    @apply py-[23px] px-[22px] w-full !text-primary text-sm sm:text-base font-bold
}

.project-overview .card-section img {
    @apply w-full h-[300px] object-cover
}

.project-overview .title {
    @apply text-secondary text-2xl sm:text-3xl 1xl:text-[2.5rem] 1xl:leading-[41px] font-bold font-caudex
}

.project-overview .section {
    @apply flex flex-col pt-[30px]
}

.project-overview .section .subtitle {
    @apply text-secondary text-base sm:text-lg 1xl:text-xl font-bold
}

.project-overview .section .subheading {
    @apply text-[#2D2D2D] text-sm sm:text-base font-bold
}

.project-overview .section .line-svg {
    @apply my-[8px] 1xl:my-[12px] max-w-[1241px] w-full
}

.project-overview .section .desc {
    @apply text-darkGray font-normal text-xs sm:text-sm
}

.project-overview .section .desc-sec {
    @apply text-xs sm:text-sm 1xl:text-base
}

.project-overview .section .list {
    @apply flex flex-col gap-y-[6px]
}

.project-overview .section .bullet-list-section {
    @apply grid grid-cols-1 sm:grid-cols-2
}

.project-overview .section .img-section {
    @apply grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 xxl:grid-cols-4 gap-[20px] pt-[25px]
}

.project-overview .section .img {
    @apply w-full h-[200px] object-cover rounded-[15px]
}

.project-overview .section ._img {
    @apply w-full object-cover rounded-[10px] h-[242px]
}

.project-overview .section .img-section .desc {
    @apply text-center pt-[10px] text-xs text-[rgb(109,109,109)] font-normal
}

.project-overview .section .list-section {
    @apply flex
}

.dark .list-section ul {
    @apply *:!text-white
}

.project-overview .section .list-section a {
    @apply text-primary
}

.project-overview .section .list .list-section .bullet-svg {
    @apply pr-[8px]
}

.project-overview .section .list .list-section .bullet-svg svg {
    @apply sm:mt-[2px]
}

.project-overview .section .list .list-section .link {
    @apply text-secondary cursor-pointer text-xs sm:text-sm font-normal focus:underline hover:underline
}

.project-overview .section .rti-link {
    @apply text-primary font-normal text-xs sm:text-sm focus:underline hover:underline break-all
}

.project-overview .project-status-img {
    @apply mt-[30px] w-full xs:w-[400px] h-[300px] xs:h-[350px]
}

.project-overview .section .grid-content {
    @apply grid grid-cols-1 mm:grid-cols-2 1xl:grid-cols-3 gap-[15px]
}

.project-overview .section .grid-content .content-title {
    @apply text-xs sm:text-sm text-[#2D2D2D] font-bold pb-[8px]
}

.project-overview .section .img-content {
    @apply flex gap-[30px] flex-col 1xl:flex-row
}

.project-overview .section .img-content .map-img {
    @apply rounded-[20px] h-[425px] object-cover w-full 1xl:w-auto
}

.project-overview .strong-content {
    @apply text-[#2D2D2D] dark:text-white text-xs sm:text-sm font-bold
}

.project-overview .near-places {
    @apply mt-[20px]
}

.project-overview .near-places .near-places-grid {
    @apply grid grid-cols-1 xxl:grid-cols-2 gap-[15px] mt-[15px]
}

.project-overview .near-places .place-content {
    @apply flex items-center
}

.project-overview .near-places .place-content .place-content-icon {
    @apply pr-[8px]
}

.dark .place-content {
    filter: brightness(0) invert(1);
}

.project-overview .near-places .place-content .place-content-title {
    @apply text-primary text-xs sm:text-sm font-bold
}

.project-overview .download-section {
    @apply w-full min-h-[120px] bg-[#D9D9D9] bg-opacity-[0.2] rounded-[30px] p-[15px] sm:p-[30px] 1xl:px-[59px] flex items-center justify-between mt-[20px] gap-[10px] xsx:flex-row flex-col
}

.project-overview .download-section .download-section-content {
    @apply flex flex-col
}

.project-overview .download-section .download-section-content {
    @apply flex flex-col
}

.project-overview .download-section .download-section-content .download-title {
    @apply text-secondary text-base 1xl:text-xl font-bold
}

.project-overview .download-section .download-section-content .download-desc {
    @apply text-darkGray text-xs sm:text-sm font-bold max-w-[336px]
}

.project-overview .download-section .download-btn {
    @apply rounded-[10px]  min-h-[40px] w-fit shadow-[0px_0.895px_3.579px_0px_rgba(0,0,0,0.25)] flex items-center justify-center !text-white text-sm font-bold bg-primary mt-[16px] disabled:opacity-[0.3] disabled:cursor-not-allowed   focus:shadow-[0_0_6px_0px_#61095A] hover:shadow-[0_0_6px_0px_#61095A] dark:hover:shadow-[0_0_5px_0px_#ffffff] dark:focus:shadow-[0_0_5px_0px_#ffffff] px-[24px] whitespace-nowrap
}

.project-overview .contact-section {
    @apply w-full min-h-[120px] bg-[#D9D9D9] bg-opacity-[0.2] rounded-[30px] p-[15px] sm:p-[30px] 1xl:px-[59px] flex items-center mt-[20px] sm:mt-[40px] gap-y-[10px] gap-x-[70px] xxl:gap-x-[150px] flex-wrap
}

.project-overview .contact-section .contact-section-content {
    @apply flex
}

.project-overview .contact-section .contact-section-content-icon {
    @apply pr-[15px] pt-[8px]
}

.project-overview .contact-section .contact-section-content-icon .contact-icon {
    @apply 1xl:w-auto 1xl:h-auto w-[25px] h-[25px]
}

.project-overview .contact-section .contact-section-content .content {
    @apply flex flex-col
}

.project-overview .contact-section .contact-section-content .content .contact-subsection {
    @apply flex gap-[15px] ss:gap-[40px] flex-col ss:flex-row
}

.project-overview .contact-section .contact-section-content .content .contact-title {
    @apply text-secondary dark:text-white text-base 1xl:text-xl font-bold break-all
}

.project-overview .contact-section .contact-section-content .content .contact-subtitle {
    @apply text-[#2D2D2D] dark:text-white text-sm font-bold break-all
}

.project-overview .contact-section .contact-section-content .content .contact-desc {
    @apply text-xs sm:text-sm font-bold text-darkGray break-all 
}

.project-overview .contact-section .contact-section-content .content .contact-desc a {
    @apply focus:underline hover:underline
}

/* about us page */

.about .journey {
    @apply  flex w-full 
}

.about .journey .title {
    @apply text-secondary font-caudex text-xl sm:text-2xl 1xl:text-3xl font-bold pb-[8px]
}

.about .journey .subtitle {
    @apply text-secondary text-base 1xl:text-xl font-bold pt-[25px] pb-[8px]
}

.about .journey .line-svg {
    @apply max-w-[1241px] w-full
}

.dark .line-svg {
    filter: brightness(0) invert(1);
}

.about .journey .desc {
    @apply text-darkGray text-sm 1xl:text-base font-normal
}

.about .about-container {
    @apply w-full h-full flex flex-col gap-[30px] md:gap-[60px] 1xl:gap-[100px]
}

.about .about-container .journey-mission {
    @apply flex flex-col
}

.about .about-container .journey-mission .journey-person {
    @apply py-[30px] md:py-[50px] xxl:py-[60px]
}

.about .about-container .journey-mission .title {
    @apply text-primary font-caudex text-xl sm:text-2xl 1xl:text-3xl font-bold max-w-[521px]
}

.about .about-container .journey-mission .person-info-section {
    @apply w-full grid grid-cols-1 md:grid-cols-2 1xl:grid-cols-4 xxl:grid-cols-4 gap-y-[110px] sm:gap-y-[120px] gap-x-[20px] 1xl:gap-x-[30px] pt-[110px] sm:pt-[115px] xxl:pt-[140px]
}

.about .about-container .journey-mission .person-info-section .card-section {
    @apply w-full h-full cursor-pointer rounded-[15px] bg-[#ffffff26] dark:!bg-[#333333]
}

.about .about-container .journey-mission .person-info-section .card-section:hover {
    background: linear-gradient(192deg, #61095A 19.51%, #8A0680 82.3%);
    box-shadow: 0px 13.953px 68.216px -3.101px rgba(24, 39, 75, 0.14), 0px 6.201px 21.705px -4.651px rgba(24, 39, 75, 0.12);
}

.about .about-container .journey-mission .person-info-section .card-section:hover .card-desc,
.about .about-container .journey-mission .person-info-section .card-section:hover .card-title {
    color: white !important;
}

.dark .about .about-container .journey-mission .person-info-section .card-section:hover .card-title {
    color: #ffff00 !important;
}

.about .about-container .journey-mission .person-info-section .card-section:hover .card-subtitle {
    color: #C2C2C2 !important;
}

.about .about-container .journey-mission .person-info-section .card-section .card {
    @apply w-full h-full rounded-[15px] px-[28px] pb-[30px] pt-[75px] relative border border-[#E2E2E2]
}

.about .about-container .journey-mission .person-info-section .card-section .card .card-img {
    @apply w-full flex items-start justify-center top-[-80px] left-0 right-0 absolute
}

.about .about-container .journey-mission .person-info-section .card-section .card .card-desc {
    @apply text-[#969696] text-xs sm:text-sm font-normal pt-[20px]
}

.about .about-container .journey-mission .person-info-section .card-section .card .card-title {
    @apply text-secondaryText text-xs 1xl:text-sm font-bold
}

.about .about-container .journey-mission .person-info-section .card-section .card .card-subtitle {
    @apply text-xs text-darkGray font-bold
}

.about .about-container .journey-mission .mission-vission-section {
    @apply w-full flex items-center justify-center 
}

.about .about-container .journey-mission .mission-vission-section .mission-vission {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] flex flex-col 1xl:flex-row items-center gap-[30px] 1xl:gap-[34px] justify-between 1xl:w-[1240px]
}

.about .about-container .journey-mission .mission-vission-section .mission-vission .mission-vission-content {
    @apply flex flex-col gap-[15px]
}

.about .about-container .journey-mission .mission-vission-section .mission-vission .mission-vission-content .title {
    @apply text-primary font-caudex text-xl sm:text-2xl 1xl:text-3xl font-bold sm:max-w-[300px]
}

.about .about-container .journey-mission .mission-vission-section .mission-vission .mission-vission-content .desc {
    @apply text-darkGray font-normal text-xs sm:text-sm 1xl:text-base sm:max-w-[500px] 1xl:max-w-[340px]
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card {
    @apply flex flex-col md:flex-row items-center justify-center gap-[30px] md:h-[480px] relative
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card .section {
    @apply flex items-start justify-start h-full
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card .section .mission {
    @apply max-w-[310px] h-[330px] sm:h-[387px] py-[32px] px-[23px] relative rounded-[20px] flex flex-col justify-between items-start shadow-[0px_6.201px_21.705px_-4.651px_rgba(24,39,75,0.12),0px_13.953px_68.216px_-3.101px_rgba(24,39,75,0.14)]
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card .section .mission .pattern-img {
    @apply absolute top-0 left-[-160px] right-0 bottom-0 flex items-center justify-start
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card .section .mission .mission-img {
    @apply absolute bottom-0 right-0
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card .section .mission .desc {
    @apply text-white text-xs sm:text-sm font-normal
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card .section .mission .title {
    @apply text-white text-xs sm:text-lg 1xl:text-xl font-caudex font-bold
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card .vission {
    @apply h-full flex items-end justify-end
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card .vission .vission-section {
    @apply bg-white max-w-[310px] h-[330px] sm:h-[387px] py-[32px] px-[23px] relative rounded-[20px] flex flex-col justify-between items-start shadow-[0px_2px_4px_-2px_rgba(24,39,75,0.12),0px_4px_4px_-2px_rgba(24,39,75,0.08)]
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card .vission .vission-section .vission-img {
    @apply absolute bottom-0 right-0
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card .vission .vission-section .desc {
    @apply text-darkGray text-xs sm:text-sm font-normal
}

.about .about-container .journey-mission .mission-vission-section .mission-vission-card .vission .vission-section .title {
    @apply text-darkGray text-xs sm:text-lg 1xl:text-xl font-caudex font-bold
}

.about .about-director {
    @apply w-full h-full bg-[#F9F9F9]
}

.about .about-director .director-section {
    @apply relative w-full h-full
}

.about .about-director .director-section .pattern {
    @apply absolute top-0 right-0 bottom-0 w-full h-full
}

.about .about-director .director-section .director-card {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] 1xl:py-[80px] flex flex-col mm:flex-row items-center justify-center gap-[20px] md:gap-[35px] lg:gap-[50px] 1xl:gap-[65px]
}

.about .about-director .director-section .director-card .director-img {
    @apply w-[180px] h-[200px] sm:w-[220px] sm:h-[250px] mm:w-auto mm:h-auto relative z-[1]
}

.about .about-director .director-section .director-card .director-content {
    @apply flex flex-col
}

.about .about-director .director-section .director-card .director-content .upmrc {
    @apply w-fit h-fit
}

.about .about-director .director-section .director-card .director-content .content-section {
    @apply flex flex-col
}

.about .about-director .director-section .director-card .director-content .content-section .director-title {
    @apply text-secondary font-bold text-base sm:text-2xl 1xl:text-3xl font-caudex pb-[8px] lg:pb-[15px] pt-[10px] lg:pt-[25px]
}

.about .transformation {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] 1xl:py-[100px] flex items-start flex-col w-full
}

.about .transformation .title {
    @apply text-secondary font-caudex text-xl sm:text-2xl 1xl:text-3xl font-bold
}

.about .transformation .desc {
    @apply text-darkGray text-xs sm:text-sm 1xl:text-base font-normal pt-[10px] max-w-[794px]
}

.about .transformation .transformation-table {
    @apply w-full pt-[30px] 1xl:pt-[40px]
}

.about .transformation .transformation-table .section {
    @apply overflow-x-auto
}

.about .transformation .transformation-table .section .subsection {
    @apply inline-block min-w-full
}

.about .transformation .transformation-table .section .subsection .section-content {
    @apply overflow-hidden
}

.about .transformation .transformation-table .section .subsection .section-content table {
    @apply min-w-full
}

.about .transformation .transformation-table .section .subsection .section-content table thead {
    @apply bg-primary
}

.about .transformation .transformation-table .section .subsection .section-content table thead tr {
    @apply h-[32px]
}

.about .transformation .transformation-table .section .subsection .section-content table thead tr th {
    @apply px-[11px] h-full text-white font-bold text-xs 1xl:text-sm text-left
}

.about .transformation .transformation-table .section .subsection .section-content table thead tr .first-title {
    @apply rounded-l-[10px] !rounded-b-none
}

.about .transformation .transformation-table .section .subsection .section-content table thead tr .last-title {
    @apply rounded-r-[10px] !rounded-b-none
}

.about .transformation .transformation-table .section .subsection .section-content table tbody tr {
    @apply border-b border-[#EAECF0] bg-white
}

.about .transformation .transformation-table .section .subsection .section-content table tbody tr .heading {
    @apply px-[8px] py-[8px] text-[#101828] text-xs 1xl:text-sm font-normal whitespace-nowrap text-left
}

.about .transformation .transformation-table .section .subsection .section-content table tbody tr .content {
    @apply px-[8px] py-[8px] text-[#667085] text-xs 1xl:text-sm font-normal whitespace-nowrap text-left
}

.about .certificate {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] 1xl:py-[100px] w-full h-full
}

.about .certificate .certificate-section {
    @apply flex flex-col w-full h-full
}

.about .certificate .certificate-section .title {
    @apply text-secondary font-caudex text-xl sm:text-2xl 1xl:text-3xl font-bold
}

.about .certificate .certificate-section .desc {
    @apply text-darkGray text-xs sm:text-sm 1xl:text-base font-normal pt-[8px] max-w-[523px]
}

.about .certificate .certificate-section .certificate-list {
    @apply pt-[54px] grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 1xl:grid-cols-6 gap-[25px]
}

.about .certificate .certificate-section .card {
    @apply p-[6px] flex flex-col gap-y-[11px] border border-white hover:border-[#61095A] bg-white rounded-[10px] shadow-[0px_3.027px_3.027px_-1.514px_rgba(24,39,75,_0.08),0px_1.514px_3.027px_-1.514px_rgba(24,39,75,0.12)] cursor-pointer
}

.about .certificate .certificate-section .card .card-title {
    @apply text-darkGray text-[10px] font-bold px-[8px]
}

.tender .desc {
    @apply w-full text-darkGray text-xs sm:text-sm font-normal pt-[4px]
}

.tender .title {
    @apply px-[8px] py-[15px] text-primary text-xs 1xl:text-sm font-normal w-[350px]
}

/* table */

.table-data {
    @apply w-full py-[30px] md:py-[50px] xxl:py-[60px]
}

.table-data .main-title {
    @apply text-secondary text-base sm:text-lg 1xl:text-xl font-bold pb-[30px]
}

.table-data .section {
    @apply overflow-x-auto block
}

.table-data .section .subsection {
    @apply inline-block min-w-full
}

.table-data .desc {
    @apply text-darkGray font-normal text-xs sm:text-sm
}

.table-data .section .subsection .section-content {
    @apply overflow-hidden
}

.table-data .section .subsection .section-content table {
    @apply min-w-full
}

.table-data .section .subsection .section-content table thead {
    @apply bg-primary
}

.table-data .section .subsection .section-content table thead tr {
    @apply h-[32px]
}

.table-data .section .subsection .section-content table thead tr th {
    @apply px-[11px] h-full text-white font-bold text-xs 1xl:text-sm text-left
}

.table-data .section .subsection .section-content table thead tr .heading-title {
    @apply rounded-t-[10px] !rounded-b-none
}

.table-data .section .subsection .section-content table thead tr .first-title {
    @apply rounded-l-[10px] !rounded-b-none
}

.table-data .section .subsection .section-content table thead tr .last-title {
    @apply rounded-r-[10px] !rounded-b-none
}

.table-data .section .subsection .section-content table thead tr .title-center,
.table-data .section .subsection .section-content table tbody tr .title-center {
    @apply !text-center
}

.table-data .section .subsection .section-content table tbody tr {
    @apply border border-[#EAECF0] bg-white
}

.table-data .section .subsection .section-content table tbody td {
    @apply border-r border-[#EAECF0] bg-white
}

.table-data .section .subsection .section-content table tbody tr .heading {
    @apply px-[8px] py-[8px] text-[#101828] text-xs 1xl:text-sm font-normal whitespace-nowrap text-left
}

.table-data .section .subsection .section-content table tbody tr .content {
    @apply px-[8px] py-[8px] text-[#667085] text-xs 1xl:text-sm font-normal text-left
}

.table-data .section .subsection .section-content table tbody tr .content .link {
    @apply  hover:border-b hover:border-primary focus:border-b focus:border-primary text-primary text-xs 1xl:text-sm font-normal focus:border-opacity-[0.6] hover:border-opacity-[0.6]
}

.table-data .section .subsection .section-content table tbody tr .content-center {
    @apply px-[8px] py-[8px] text-[#667085] text-xs 1xl:text-sm font-normal text-center
}

.table-data .section .subsection .section-content table tbody tr .content-center .link {
    @apply  hover:border-b hover:border-primary focus:border-b focus:border-primary text-primary text-xs 1xl:text-sm font-normal 
}

.table-data .section .subsection .section-content table tbody tr .special-content {
    @apply flex items-center gap-[4px]
}

.table-data .section .subsection .section-content table tbody tr .content .download-section {
    @apply flex flex-col items-start justify-center w-full
}

.table-data .section .subsection .section-content table tbody tr .content .download-section .download-link {
    @apply flex items-center gap-[6px] cursor-pointer  focus:shadow-[0_0_6px_0px_#32435F] hover:shadow-[0_0_6px_0px_#32435F] dark:focus:shadow-[0_0_5px_0px_#ffffff] dark:hover:shadow-[0_0_5px_0px_#ffffff] rounded-[12px] px-[6px]
}

.dark strong {
    @apply !text-white
}

.dark .table-data .section .subsection .section-content table tbody tr .content .download-section .download-link {
    filter: brightness(0) invert(1);
}

.table-data .section .subsection .section-content table tbody tr .content .download-section .download-link .download-text {
    @apply text-[#667085] dark:!text-white text-xs 1xl:text-sm font-bold
}

.table-data .section .subsection .section-content table tbody tr .content .download-size {
    @apply text-[#667085] text-[0.625rem] font-normal whitespace-nowrap pl-[20px] uppercase
}

.table-data .section .subsection .section-content table tbody tr .content .download-list {
    @apply flex flex-col gap-[24px] items-center justify-center w-full
}

/* rti contact */

.rti-online {
    @apply flex items-center justify-center py-[30px] md:py-[50px] xxl:py-[60px]
}

.rti-online .rti-section {
    @apply w-full 1xl:w-auto 1xl:min-w-[1100px] flex flex-col gap-[20px]
}

.rti-online .rti-sec-section {
    @apply flex items-center justify-center gap-[30px] md:gap-[50px] xxl:gap-[100px] !w-auto !flex-col mdm:!flex-row
}

.rti-online .rti-section .title {
    @apply text-base 1xl:text-2xl font-bold text-secondary
}

.rti-online .rti-section .contact {
    @apply bg-white p-[20px] shadow-xl flex flex-col gap-[8px] rounded-2xl
}

.rti-online .rti-section .contact-sec {
    @apply max-w-[515px] relative pb-[40px]
}

.rti-online .rti-section .contact div {
    @apply flex items-center 
}

.rti-online .rti-section .contact div h2 {
    @apply text-darkGray text-sm xxl:text-base font-normal
}

.rti-online .rti-section .contact div a {
    @apply  hover:underline focus:underline text-primary font-bold text-sm xxl:text-base
}

/* cvo-profile */

.cvo-profile {
    @apply pt-[30px]
}

.cvo-profile .cvo-profile-section {
    @apply max-w-[1100px] flex flex-col gap-[15px] w-full 1xl:w-auto 1xl:max-w-[900px]
}

.cvo-profile .cvo-profile-section .profile {
    @apply bg-white p-[30px] shadow-xl flex flex-col gap-[25px] rounded-2xl w-full
}

.cvo-profile .cvo-profile-section .profile .profile-content {
    @apply w-full flex items-center sm:items-start flex-col sm:flex-row justify-center sm:justify-between gap-[20px]
}

.cvo-profile .cvo-profile-section .profile .profile-content div {
    @apply flex flex-col
}

.cvo-profile .cvo-profile-section .profile .profile-content div h2 {
   @apply text-secondary text-base 1xl:text-xl font-bold
}

.cvo-profile .cvo-profile-section .profile .profile-content div p {
    @apply text-darkGray text-sm pt-[8px]
 }

 .cvo-profile .cvo-profile-section .profile .profile-content div a {
    @apply  hover:underline focus:underline text-primary
 }

 .cvo-profile .cvo-profile-section .profile .desc {
    @apply text-sm text-darkGray font-normal
 }

 .more-details .link {
    @apply text-primary font-bold text-xs sm:text-sm focus:underline hover:underline
}

 /* publication */

 .publication {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 gap-x-[20px] gap-y-[25px]
 }

 .publication-card {
    @apply rounded-[10px] bg-white shadow-[0px_4px_4px_0px_rgba(192,189,189,0.25)] p-[19px]  focus:shadow-[0px_12px_42px_-4px_rgba(24,39,75,0.12),0px_8px_18px_-6px_rgba(24,39,75,0.12)] hover:shadow-[0px_12px_42px_-4px_rgba(24,39,75,0.12),0px_8px_18px_-6px_rgba(24,39,75,0.12)] cursor-pointer
 }

 .publication-card img {
    @apply w-[142px] h-[167px] object-cover
 }

 .publication-card .content {
    @apply flex items-center gap-[7px]
 }

 .publication-card h2 {
    @apply text-darkGray text-xs sm:text-sm font-normal pt-[8px]
 }

 .publication-card h5 {
    @apply text-[#000] text-sm sm:text-base font-bold pt-[10px]
 }

 .publication-card p {
    @apply text-xs font-normal text-darkGray pt-[10px]
 }

 /* press-release */

 .press-release {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 gap-x-[20px] gap-y-[25px]
 }

 .press-release-card {
    @apply rounded-[5px] bg-[#fcfcfc]  focus:shadow-[0px_4px_4px_0px_rgba(192,189,189,0.25)] 
 }

 .press-release-section {
    @apply w-full h-full
 }

 .press-release-card:hover {
    box-shadow: 0px 4px 4px 0px rgba(192, 189, 189, 0.25);
    background-color: white;
 }

 .press-release-card img {
    @apply h-[179px] w-full object-cover rounded-t-[5px]
 } 

 .press-release-card .content {
    @apply py-[23px] px-[22px] w-full flex flex-col
 }

 .press-release-card .content h1 {
    @apply text-primary text-sm sm:text-base font-bold
 }

 .press-release-card .content p {
    @apply text-xs sm:text-sm text-darkGray font-normal pt-[30px] line-clamp-2
 }

 .press-release-card .content p {
    @apply text-xs sm:text-sm text-darkGray font-normal pt-[30px]
 }

 .press-release-card .content div {
    @apply flex items-center justify-between w-full pt-[20px]
 }

 .press-release-card .content div h2 {
    @apply text-xs text-darkGray font-normal
 }

 .press-release-card .content div button {
    @apply text-xs text-darkGray font-bold cursor-pointer  focus:border-b focus:border-[#6D6D6D] hover:border-b hover:border-[#6D6D6D] h-[15px]
 }

 /* image-gallery */

 .image-gallery {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 gap-x-[20px] gap-y-[25px]
 }

 .image-gallery .image-gallery-card {
    @apply bg-[#F4F4F4]  focus:bg-white focus:shadow-[0px_12px_42px_-4px_rgba(24,39,75,0.12),0px_8px_18px_-6px_rgba(24,39,75,0.12)]
 }

.image-gallery-card:hover {
    background-color: white;
    box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12);
 }

.image-gallery-card:hover .content h1 {
    color: #61095A !important;
 }

 .image-gallery .image-gallery-card .images {
    @apply grid grid-cols-3
}

.first-img {
    @apply row-span-3 col-span-2 object-cover border-[3px] border-white w-full h-full
}

.second-img,
.third-img,
.four-img {
    @apply col-span-1 object-cover border-[3px] border-white h-[100px] w-full
}

.media-first-img {
    @apply col-span-3 w-full h-[300px] object-cover border-[3px] border-white
}

.media-second-img {
    @apply col-span-1 row-span-3 object-cover border-[3px] border-white h-[300px] w-full
}

.media-third-img {
    @apply col-span-1 row-span-2 object-cover border-[3px] border-white h-[200px] w-full
}

.image-gallery .image-gallery-card .content h1 {
    @apply text-darkGray p-[15px] !pt-[10px] text-sm sm:text-base font-bold
}

/* image-gallery-detail */

.image-gallery-detail {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-4 gap-[10px]
}

.image-gallery-detail img {
    @apply rounded-[15px] border-[3px] border-white h-[300px] object-cover w-full
}

/* video-gallery */

.video-gallery {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 gap-x-[20px] gap-y-[25px]
}

.video-gallery .video-gallery-card {
    @apply bg-[#F4F4F4]  focus:bg-white focus:shadow-[0px_12px_42px_-4px_rgba(24,39,75,0.12),0px_8px_18px_-6px_rgba(24,39,75,0.12)] p-[15px] w-full cursor-pointer
 }

.video-gallery-card:hover {
    background-color: white;
    box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12), 0px 8px 18px -6px rgba(24, 39, 75, 0.12);
 }

 .video-gallery .video-gallery-card .content h1 {
    @apply text-darkGray dark:!text-darkGray pt-[10px] text-sm sm:text-base font-bold
}

.video-gallery-card:hover .content h1 {
    color: #61095A !important;
 }

.video-gallery .main-video {
    @apply col-span-3 w-full h-full flex items-center justify-center
}

.video-gallery .main-video video {
    @apply w-full h-[500px] max-w-full object-cover
}

.video-gallery .video {
    @apply w-full h-full flex flex-col items-start justify-center
}

.video-gallery .video iframe,
.video-gallery .video video {
    @apply w-full h-[275px] max-w-full object-cover
}

/* press-release-detail */
.press-release-detail {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] flex items-start flex-col lg:flex-row gap-[38px]
}

.press-release-detail .img-section {
    @apply flex flex-col gap-[12px] w-full xs:w-auto xs:min-w-[453px] h-full
}

.press-release-detail .img-section img {
    @apply w-full h-[350px] object-cover
}

.press-release-detail .img-section .img-content {
    @apply w-full flex items-center justify-between
}

.press-release-detail .img-section .img-content h2 {
    @apply text-[#2D3748] text-sm sm:text-base 1xl:text-lg font-bold
}

.press-release-detail .img-section .img-content p {
    @apply text-[#718096] text-sm sm:text-base 1xl:text-lg font-normal
}

.press-release-detail .content {
    @apply flex flex-col
}

.press-release-detail .content .title {
    @apply text-xl sm:text-2xl 1xl:text-3xl font-bold font-caudex border-b border-[#E8E7E7] pb-[10px] sm:pb-[20px]
}

.press-release-detail .content .main-desc {
    @apply text-[#718096] text-xs sm:text-sm 1xl:text-base font-normal pt-[10px] sm:pt-[20px]
}

.press-release-detail .content .list-section {
    @apply flex
}

.press-release-detail .content .list-section .bullet-svg {
    @apply pr-[8px]
}

.press-release-detail .content .list-section .bullet-svg svg {
    @apply mt-[1px]
}

.dark .bullet-svg {
    filter: brightness(0) invert(1);
}

.dark .bullet {
    filter: brightness(0) invert(1);
}

.press-release-detail .content .list-section .desc {
    @apply text-[#718096] text-xs sm:text-sm 1xl:text-base font-normal
}

/* metro-timing */

.metro-timing {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] flex flex-col items-center justify-center xl:min-w-[1210px] gap-[60px] w-full
}

.metro-timing .metro-timing-card {
    @apply w-full flex flex-col lg:flex-row items-center justify-center gap-[25px]
}

.metro-timing .metro-timing-card .metro-timing-img {
    @apply rounded-[30px] relative w-full sm:w-[600px] h-[320px]
}

.metro-timing .metro-timing-card .metro-timing-img img {
    @apply rounded-[30px] w-full sm:w-[600px] h-[320px] object-cover
}

.metro-timing .metro-timing-card .metro-timing-img .first-time-card {
    @apply absolute top-0 left-0 right-0 bottom-0 flex items-end justify-end w-full h-full p-[15px]
}

.metro-timing .metro-timing-card .metro-timing-img .first-time-card .first-card {
    @apply rounded-[20px] border-[2px] border-[#98F9FF] bg-blend-overlay backdrop-blur-[40px] p-[18px]
}

.metro-timing .metro-timing-card .metro-timing-img .first-time-card .first-card .first-card-content {
    @apply flex items-center gap-[9px]
}

.metro-timing .metro-timing-card .metro-timing-img .first-time-card .first-card .first-card-content h1 {
    @apply text-primary font-caudex text-2xl sm:text-3xl 1xl:text-[2.5rem] 1xl:leading-[48px] font-bold
}

.metro-timing .metro-timing-card .metro-timing-img .first-card p {
    @apply text-primary text-sm sm:text-base font-bold
}

.metro-timing .metro-timing-card .metro-timing-img .second-time-card {
    @apply absolute top-0 left-0 right-0 bottom-0 flex items-end justify-start w-full h-full p-[15px]
}

.metro-timing .metro-timing-card .metro-timing-img .second-time-card .second-card {
    @apply rounded-[20px] border-[2px] border-[#98F9FF] bg-blend-overlay backdrop-blur-[40px] p-[18px]
}

.metro-timing .metro-timing-card .metro-timing-img .second-time-card .second-card .second-card-content {
    @apply flex items-center gap-[9px]
}

.metro-timing .metro-timing-card .metro-timing-img .second-time-card .second-card .second-card-content h1 {
    @apply text-white font-caudex text-2xl sm:text-3xl 1xl:text-[2.5rem] 1xl:leading-[48px] font-bold
}

.metro-timing .metro-timing-card .metro-timing-img .second-card p {
    @apply text-white text-sm sm:text-base font-bold
}

.metro-timing .content h2 {
    @apply text-primary font-caudex font-bold text-base sm:text-2xl 1xl:text-3xl flex items-center gap-[8px]
}

.metro-timing .content p {
    @apply text-darkGray text-xl font-bold text-center pt-[8px]
}

/* go-smart-card */

.go-smart-card {
    @apply py-[30px] md:py-[50px] xxl:py-[60px]
}

.go-smart-card .go-smart-card-section {
    @apply flex items-center justify-center py-[80px] rounded-[30px]
}

.go-smart-card .go-smart-card-img {
    @apply w-full min-h-[400px] xxl:max-w-[1352px] relative rounded-[30px] flex items-center justify-start gap-[30px] p-[30px] sm:p-[40px] 1xl:px-[60px] xxl:px-[120px] mm:flex-row flex-col
}

.go-smart-card .go-smart-card-img .go-smart-card-content {
    @apply flex flex-col 
}

.go-smart-card .go-smart-card-img .go-smart-card-content .title {
    @apply text-primary dark:!text-primary font-caudex text-2xl sm:text-3xl 1xl:text-[2.5rem] 1xl:leading-[48px] font-extrabold
}

.go-smart-card .go-smart-card-img .go-smart-card-content .desc {
    @apply text-darkGray dark:!text-darkGray font-bold text-sm sm:text-base max-w-[500px] mm:max-w-[350px] xxl:max-w-[500px] mt-[4px]
}

.go-smart-card .go-smart-card-img .go-smart-card-content .btn {
    @apply   shadow-[0px_1px_4px_0px_rgba(0,0,0,0.25)] rounded-[10px] h-[40px] bg-primary px-[24px] w-fit text-white text-sm 1xl:text-base font-bold flex items-center justify-center focus:shadow-[0_0_6px_0px_#61095A] hover:shadow-[0_0_6px_0px_#61095A] mt-[15px]
}

.go-smart-card .go-smart-card-img .card-img {
    @apply mm:absolute mm:right-[60px] xxl:right-[120px] z-[1]
}

.go-smart-card .go-smart-card-img img {
    @apply 1xl:w-auto 1xl:h-auto mm:w-[400px] mm:h-[400px]
}


/* faqs */

.faqs {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] flex flex-col gap-[15px]
}

.faqs .link {
    @apply  hover:border-b hover:border-primary focus:border-b focus:border-primary text-primary text-xs 1xl:text-sm font-normal focus:border-opacity-[0.6] hover:border-opacity-[0.6]
}

  .wrap-collabsible input[type='checkbox'] {
    display: none;
  }

  .lbl-toggle {
    @apply text-primary shadow-sm block font-bold text-sm sm:text-base text-left px-[25px] py-[10px] border-l-[5px] border-primary cursor-pointer rounded-[7px] bg-[#f9f9f9]
  }

  .link-toggle {
    @apply hover:underline focus:underline
  }

  .lbl-toggle {
    transition: all 0.25s ease-out;
  }

  .collapsible-content .content-inner p,
  .collapsible-content .content-inner li {
    @apply text-darkGray text-xs sm:text-sm font-normal
  }

  .lbl-toggle::before {
    content: ' ';
    display: inline-block;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid currentColor;
    vertical-align: middle;
    margin-right: .7rem;
    transform: translateY(-2px);
    transition: transform .2s ease-out;
  }

  .collapsible-content .content-inner {
    background: white;
    border-bottom: 1px solid #ebebeb;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: .5rem 1rem;
  }

  .collapsible-content {
    max-height: 0px;
    overflow: hidden;
    transition: max-height .25s ease-in-out;
  }

  .collapsible-content .search-content > :first-child {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .toggle:checked + .lbl-toggle + .collapsible-content {
    max-height: fit-content;
  }

  .toggle:checked + .lbl-toggle::before {
    transform: rotate(90deg) translateX(-3px);
  }

  /* your-metro */
.your-metro {
    @apply py-[30px] md:py-[50px] xxl:py-[60px] flex items-start md:gap-[25px] flex-col md:flex-row
}

.your-metro .menu {
    @apply rounded-[20px] py-[17px] px-[12px] w-full md:w-auto md:min-w-[260px] flex flex-col gap-[7px] md:sticky md:top-[33px]
}

.your-metro .menu .active {
    @apply flex items-center justify-between bg-primary rounded-[8px] px-[15px] min-h-[39px] !text-white text-sm font-bold  focus:shadow-[0_0_6px_0px_#61095A] focus:border-primary
}

.your-metro .inactive {
    @apply flex items-center justify-start px-[15px] min-h-[39px] text-[#A1A1A1] dark:!text-white text-sm font-bold  focus:text-primary hover:text-primary dark:focus:!text-[#ffff00] dark:hover:!text-[#ffff00]
}

.your-metro .card-layout {
    @apply gap-[25px]
}

.your-metro .card {
    @apply xxl:px-[73px] xxl:py-[43px] p-[30px] rounded-[20px] flex items-center gap-[20px] xxl:gap-[100px] 1xl:flex-row flex-col !mr-0
}

.dark .your-metro .card:nth-child(odd) {
    background: linear-gradient(90deg, #3c3a3cb3 0%, rgb(255, 255, 255) 87%) !important;
}

.dark .your-metro .card:nth-child(even) {
    background: linear-gradient(270deg, #3c3a3cb3 0%, rgb(255, 255, 255) 87%) !important;
}

.your-metro .card-start {
    @apply !items-start
}

.your-metro .card .card-content {
    @apply flex flex-col gap-[15px]
}

.your-metro .card .card-content .title {
    @apply uppercase text-primary text-base sm:text-xl font-bold
}

.your-metro .card .card-content-desc {
    @apply flex flex-col gap-[6px]
}

.your-metro .card .card-content-desc .card-content-desc-section {
    @apply flex gap-[10px]
}

.your-metro .card .card-content-desc .card-content-desc-section .desc {
    @apply text-secondaryText text-sm font-normal
}

.your-metro .bullet {
    @apply min-w-[13px] pt-[4px]
}

.your-metro .img-svg {
    @apply w-fit sm:min-w-[260px] flex items-center justify-center
}

.your-metro .icon-content {
    @apply flex gap-[23px]
}

.your-metro .icon-content-section {
    @apply flex flex-col gap-[20px]
}

.your-metro .card-content-section {
    @apply flex items-center gap-[20px] xxl:gap-[100px] 1xl:flex-row flex-col
}

.your-metro .icon-svg {
    @apply min-w-[43px]
}

.your-metro .content {
    @apply flex flex-col w-full
}

.your-metro .first-content {
    @apply py-[30px] md:py-0
}

.your-metro .content .content-section {
    @apply border-b border-[#000] border-opacity-[0.1] flex items-center justify-between gap-[8px] w-full py-[30px] flex-col mm:flex-row
}

.your-metro .content .content-section .contents {
    @apply flex flex-col
}

.your-metro .content .content-section .contents .title {
    @apply text-primary text-xs sm:text-sm font-bold
}

.your-metro .content .content-section .contents .section {
    @apply flex flex-col gap-y-[6px] pt-[15px]
}

.your-metro .content .content-section .contents .section .list-section {
    @apply flex
}

.your-metro .link {
    @apply text-primary font-bold text-xs sm:text-sm focus:underline hover:underline
}

.dark .more-details {
    filter: brightness(0) invert(1);
}

.your-metro .content .content-section .contents .section .list-section .bullet-svg {
    @apply pr-[8px]
}

.your-metro .content .content-section .contents .section .list-section .desc {
    @apply text-darkGray cursor-pointer text-xs sm:text-sm font-normal max-w-[659px]
}

.your-metro .content .card-section {
    @apply flex flex-col gap-[20px] py-[15px]
}

.your-metro .content .card-section .title {
    @apply text-secondary text-base 1xl:text-xl font-bold
}

.your-metro .content .card-section .cards {
    @apply grid grid-cols-1 mm:grid-cols-2 gap-[20px]
}

.your-metro .content .card-section .cards .card-success {
    @apply bg-[#77D41B] w-full rounded-[20px] bg-opacity-[0.05] py-[21px] px-[23px] flex flex-col gap-[15px]
}

.your-metro .content .card-section .cards .card-fail {
    @apply bg-[#F00] w-full rounded-[20px] bg-opacity-[0.05] py-[21px] px-[23px] flex flex-col gap-[15px]
}

.your-metro .content .card-section .cards .title-content {
    @apply flex items-center gap-[8px]
}

.your-metro .content .card-section .cards .title-content .success-title {
    @apply text-xs sm:text-sm font-bold text-[#77D41B]
}

.your-metro .content .card-section .cards .title-content .fail-title {
    @apply text-xs sm:text-sm font-bold text-[#F00]
}

.your-metro .content .card-section .cards .desc {
    @apply text-darkGray text-xs sm:text-sm font-normal
}

.your-metro .content .card-section .cards .desc strong {
    @apply text-primary
}

.your-metro .content .imgs {
    @apply grid grid-cols-1 ss:grid-cols-2 mm:grid-cols-3 1xl:grid-cols-4 xxl:grid-cols-5 gap-[20px] 4xl:gap-[40px] bg-[#F9F9F9] dark:bg-[#44444487] rounded-[20px] p-[20px] mb-[10px] 
}

.your-metro .content .imgs .img-card {
    @apply w-full h-[180px] xsx:h-[200px] 2.5xl:h-[220px] rounded-[20px] flex flex-col gap-[15px] items-center justify-center
}

.your-metro .content .imgs .img-card h4 {
    @apply text-[#5C5C5C] text-sm font-bold text-center max-w-[115px]
}

.person-info-content p {
   @apply !text-[#969696]
}

/*dark theme css*/
.dark .your-metro .menu  {
	background: #333333 !important;
}
.dark .download-title  {
	color: #fff !important;
}
/*dark theme css*/